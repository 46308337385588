<template>
  <panel-alert title="设备解绑" isShowBottom confirmButton="解绑" width="483px" @onCloseClick="onCloseClick"
    @onConfirmClick="onConfirmClick">
    <template v-slot:content>
      <div class="unbind-sleep-machine-alert">
        <div class="unbind-sleep-machine-alert-top">
          <unit class="unbind-sleep-machine-alert-top-unit" left="已选择" :right="model.sn"></unit>
          <div class="unbind-sleep-machine-alert-top-row">
            <unit class="unbind-sleep-machine-alert-top-unit" left="绑定用户" :right="model.name">
              <template v-slot:tail>
                <p class="unbind-sleep-machine-alert-top-unit-slot">
                  {{ model.gender }}
                </p>
              </template>
            </unit>
            <unit class="
                unbind-sleep-machine-alert-top-unit
                unbind-sleep-machine-alert-top-unit-right
              " left="手机号码" :right="model.mobile"></unit>
          </div>
          <div class="unbind-sleep-machine-alert-top-row">
            <unit class="unbind-sleep-machine-alert-top-unit" left="借出日期" :right="borrowDate"></unit>
            <unit class="
                unbind-sleep-machine-alert-top-unit
                unbind-sleep-machine-alert-top-unit-right
              " left="检测报告" :right="`${reportArray.length}份`"></unit>
          </div>
        </div>
        <div class="unbind-sleep-machine-alert-bottom">
          <div class="unbind-sleep-machine-alert-bottom-cell" v-for="(item, index) of reportModelArray" :key="index">
            <p class="unbind-sleep-machine-alert-bottom-cell-name">
              {{ item.userName }}
            </p>
            <p class="unbind-sleep-machine-alert-bottom-cell-time">
              {{ item.reportTime }}
            </p>
            <p class="unbind-sleep-machine-alert-bottom-cell-ahi">
              {{ item.reportInfo }}
            </p>
            <button v-if="item.hasReport" class="unbind-sleep-machine-alert-bottom-cell-check" @click="onCheck(index)">
              查看
            </button>
          </div>
        </div>
      </div>
    </template>
  </panel-alert>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import Unit from "@c/common/alert/panel-alert/components/key-value-unit.vue";
import { DateTool } from "@js/date-tool.js";
import { SleepItemModel } from "@js/model/sleep-item-model.js";

export default {
  components: {
    PanelAlert,
    Unit,
  },

  props: {
    model: Object, //{name,gender,mobile,suid,sn,start,end}
  },

  data: function () {
    return {
      reportArray: [],
    };
  },

  created() {
    this.getSleepReportList();
  },

  computed: {
    borrowDate() {
      return placeholderText(DateTool.timeStrToStr(this.model.start, "YYYY.MM.DD"));
    },

    reportModelArray() {
      const reportRawModelArray = this.reportArray.map((item) => {
        return new SleepItemModel(item);
      });
      const reportModelArray = reportRawModelArray.map((item) => {
        //userName
        const userName = item.userName;
        //reportTime
        const reportTimeStamp = item.reportTimeStamp;
        const reportTime = placeholderText(
          DateTool.milliTimestampToStr(reportTimeStamp, "MM月DD日 HH:mm")
        );
        //hasReport
        const hasReport = item.completion.isComplete;
        //reportInfo
        var reportInfo = "";
        if (hasReport) {
          switch (item.model.modeType) {
            case SLEEP_WORK_MODE.儿童模式:
              reportInfo = `OAHI：${item.model.ahi}`; //后台处理了
              break;
            case SLEEP_WORK_MODE.成人模式:
              reportInfo = `AHI：${item.model.ahi}`;
              break;
            default:
              break;
          }
        } else {
          switch (item.model.complete) {
            case "睡眠时间不足":
              reportInfo = "睡眠时长<3小时";
              break
            case "监测时间不足":
              reportInfo = "监测时长<4小时";
              break
            default:
              reportInfo = item.completion.text;
              break
          }
        }
        return {
          userName,
          reportTime,
          reportInfo,
          hasReport,
        };
      });
      return reportModelArray;
    },
  },

  methods: {
    onCheck(index) {
      const model = this.reportArray[index]
      const pdfUrl = model.pdfUrl;
      let routeData = this.$router.resolve({
        path: "/pdf-report",
        query: {
          pdfUrl
        },
      });
      window.open(routeData.href, "_blank");
    },

    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.unbindDevice()
    },

    async getSleepReportList() {
      if (!this.model) {
        return;
      }
      try {
        const startTimestamp = DateTool.timeStrToTimestamp(this.model.start)
        const rawEndTimestamp = DateTool.timeStrToTimestamp(this.model.end)
        const placeholderEndTimestamp = DateTool.day(new Date()).unix()
        const endTimestamp = rawEndTimestamp ?? placeholderEndTimestamp
        const params = {
          uid: this.model.suid,
          hospitalId: this.model.hospitalId,
          startTime: startTimestamp,
          endTime: endTimestamp,
        };
        const data = await this.$api.getSleepReportList(params);
        this.reportArray = data;
      } catch (error) {

      }
    },

    async unbindDevice() {
      try {
        const params = {
          sn: this.model.sn,
        };
        await this.$api.unbindUserAndSleepDevice(params);
        this.$emit("onUnbindSucceedClick");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unbind-sleep-machine-alert {
  position: relative;
  display: flex;
  flex-direction: column;

  &-top {
    display: flex;
    flex-direction: column;

    &-row {
      position: relative;
      margin-top: 50px;
      width: 100%;
      display: flex;
    }

    &-unit {
      position: absolute;

      &-slot {
        margin-left: 40px;
        font-weight: 300;
      }

      &-right {
        left: 230px;
      }
    }
  }

  &-bottom {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &-cell {
      position: relative;
      font-size: 14px;
      color: black;
      font-weight: 300;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        position: absolute;
        font-weight: 400;
      }

      &-time {
        position: absolute;
        left: 60px;
      }

      &-ahi {
        position: absolute;
        left: 230px;
      }

      &-check {
        position: absolute;
        color: #2e6be6;
        left: 380px;
      }
    }
  }
}
</style>