import { DateTool } from "@js/date-tool.js";
class SleepItemModel {

    constructor(model) {
        this.model = model
        this.userName = placeholderText(this.model.trueName);
        this.reportTimeStamp = parseInt(this.model.recordDay)
        this.isValid = this.model.complete == "完整"
        this.ahi = this.isValid ? placeholderText(this.model.ahi) : placeholderText(null)
        this.ahiLevel = this.isValid ? `（${placeholderText(this.model.ahiLevel)}）` : ""
        this.monitorDuration = this._initMonitorDuration()
        this.sleepTime = this._initSleepTime()
        this.completion = this._initCompletion()
        this.efficiency = this.isValid ? placeholderText(this.model.sleepEfficiency) : placeholderText(null)
        this.averageBloodOxygen = this._initAverageBloodOxygen()
        this.minBloodOxygen = this._initMinBloodOxygen()
        this.oxygenIndex = this.isValid ? placeholderText(this.model.diffThdLge3Pr) : placeholderText(null);
    }

    _initMonitorDuration() {
        const startTime = this.model.fallSleep;
        const endTime = this.model.getup;
        const isLessThan4 = endTime - startTime < 1000 * 60 * 60 * 4;
        const startTimeStr = DateTool.milliTimestampToStr(startTime, "HH：mm");
        const endTimeStr = DateTool.milliTimestampToStr(endTime, "HH：mm");
        let text = "";
        if (!startTimeStr || !endTimeStr) {
            text = placeholderText("");
        } else {
            text = `${startTimeStr}-${endTimeStr}${isLessThan4 ? "（<4h）" : ""}`;
        }
        return {
            text,
            isLessThan4
        };
    }

    _initSleepTime() {
        const sleepTime = this.model.lightSleepTime + this.model.deepSleepTime + this.model.remTime
        const duration = sleepTime * 60; //分钟转化为秒
        const text = placeholderText(DateTool.formatSeconds(duration));
        const isLessThan3 = !isNaN(duration) && duration < 60 * 60 * 3;
        return {
            text,
            isLessThan3
        };
    }

    _initCompletion() {
        const text = this.model.complete
        const isComplete = this.model.complete == "完整"
        return {
            text,
            isComplete,
        };
    }

    _initAverageBloodOxygen() {
        const averageBloodOxygen = parseFloat(this.model.spo2avg);
        return placeholderText(averageBloodOxygen, "%");
    }

    _initMinBloodOxygen() {
        const minBloodOxygen = parseFloat(this.model.spo2min);
        return placeholderText(minBloodOxygen, "%");
    }

}
export { SleepItemModel };
