<template>
  <div class="unit">
    <div class="unit-key">
      <p class="unit-key-text" :style="{ width: leftWidth }">{{ left }}</p>
      <p>：</p>
    </div>
    <slot name="middle"></slot>
    <p
      class="unit-value"
      :class="{ 'unit-value-hideOverflow ': isHideOverflow }"
    >
      {{ right }}
    </p>
    <slot name="tail"></slot>
  </div>
</template>

<script>
export default {
  props: {
    left: String,
    right: String,
    leftWidth: {
      type: String,
      default: "56px",
    },
    isHideOverflow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: black;
  font-size: 14px;
  line-height: 18px;
  display: flex;

  &-key {
    display: flex;

    &-text {
      width: 70px;
      text-align: end;
    }
  }

  &-value {
    font-weight: 300;
    margin-left: 14px;

    &-hideOverflow {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90px;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }
}
</style>